import Link from 'next/link';
import { memo } from 'react';
import Col from 'react-bootstrap/Col';

import styles from '@/styles/Components/FrontContainer/Footer.module.scss';

const cityFooter = {
  selection1: [
    { name: 'Karnataka', link: 'karnataka' },
    { name: 'Maharashtra', link: 'maharashtra' },
    { name: 'Tamil Nadu', link: 'tamil-nadu' },
    { name: 'Rajasthan', link: 'rajasthan' },
    { name: 'Haryana', link: 'haryana' },
    { name: 'Andhra Pradesh', link: 'andhra-pradesh' },
    { name: 'Punjab', link: 'punjab' },
    { name: 'Uttar Pradesh', link: 'uttar-pradesh' },
    { name: 'West Bengal', link: 'west-bengal' },
    { name: 'Uttarakhand', link: 'uttarakhand' },
    { name: 'Jammu Kashmir', link: 'jammu-kashmir' },
    { name: 'Kerala', link: 'kerala' },
    { name: 'Himachal Pradesh', link: 'himachal-pradesh' },
  ],
  selection2: [
    { name: 'Chennai', link: 'Chennai' },
    { name: 'Mumbai', link: 'mumbai' },
    { name: 'Hyderabad', link: 'Hyderabad' },
    { name: 'Udaipur', link: 'udaipur' },
    { name: 'Bangalore', link: 'bangalore' },
    { name: 'Pune', link: 'pune' },
    { name: 'Jaipur', link: 'jaipur' },
    { name: 'Delhi', link: 'delhi' },
    { name: 'Goa', link: 'goa' },
    { name: 'Gurgaon', link: 'gurgaon' },
    { name: 'Coimbatore', link: 'coimbatore' },
    { name: 'Mysore', link: 'mysore' },
    { name: 'Vijayawada', link: 'vijayawada' },
  ],
  selection3: [
    { name: 'Bihar', link: 'bihar' },
    { name: 'Odisha', link: 'odisha' },
    { name: 'Gujarat', link: 'gujarat' },
    { name: 'Telangana', link: 'telangana' },
    { name: 'Madhya Pradesh', link: 'madhya-pradesh' },
    { name: 'Nagaland', link: 'nagaland' },
    { name: 'Andaman Nicobar Islands', link: 'andaman-nicobar-islands' },
    { name: 'Amritsar', link: 'amritsar' },
    { name: 'Ludhiana', link: 'ludhiana' },
    { name: 'Lucknow', link: 'lucknow' },
    { name: 'Noida', link: 'noida' },
    { name: 'Mangalore', link: 'mangalore' },
    { name: 'Kolkata', link: 'kolkata' },
  ],
} as any;
const FooterCity = () => {
  return (
    <>
      <Col md="3">
        <div className="footer-widget">
          <div className={styles.footerTitle}>
            <h5>Call Girl Service</h5>
          </div>
          <div className={styles.footerContent}>
            <ul>
              {cityFooter.selection1.map((item: { name: string; link: string }, index: number) => (
                <li key={index}>
                  <Link href={`/escorts/${item.link}`} className="clickable">
                    {'Call Girls In ' + item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Col>
      <Col md="3">
        <div className="footer-widget">
          <div className={styles.footerTitle}>
            <h5>Call Girl Service</h5>
          </div>

          <div className={styles.footerContent}>
            <ul>
              {cityFooter.selection2.map((item: { name: string; link: string }, index: number) => (
                <li key={index}>
                  <Link href={`/escorts/${item.link}`} className="clickable">
                    {'Call Girls In ' + item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Col>
      <Col md="2">
        <div className="footer-widget">
          <div className={styles.footerTitle}>
            <h5>Call Girl Service</h5>
          </div>

          <div className={styles.footerContent}>
            <ul>
              {cityFooter.selection3.map((item: { name: string; link: string }, index: number) => (
                <li key={index}>
                  <Link href={`/escorts/${item.link}`} className="clickable">
                    {'Call Girls In ' + item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Col>
    </>
  );
};

export default memo(FooterCity);
